import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import {
  timeout
} from 'rxjs/operators';
import { MyDb } from '../../libs/MyDb';
import { MyUtil } from '../../libs/MyUtil';
@Injectable({
  providedIn: 'root',
})
export class Appapi {
  static syncFlags: any = {};
  appUser: any;

  constructor(public httpClient: HttpClient,
    private router: Router,
  ) {
    // do something
  };

  initializeAppUser(): Promise<any> {

    return MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(doc => {

      this.appUser = doc;
      if (this.appUser.id) {
        MyDb.initUserDb(this.appUser.id);

        MyUtil.subscribeEvent(MyUtil.EVENT.APP_RESUME, () => {
          //@TODO revise this: try to sync for every wake up if using wifi
          if (MyUtil.isNetworkWifi()) {
            MyUtil.debug('sync for wifi connection');
            let loading = MyUtil.presentLoading();
            this.syncUserAll().then(async () => {
              (await loading).dismiss();
            }).catch(async err => {
              MyUtil.error(err);
              (await loading).dismiss();
            });
          } else {
            MyUtil.debug('ignore sync for not wifi connection');
          }
        });

        // initialize firebase plugin
        MyUtil.firebaseSetUserId(this.appUser.id);
        MyUtil.firebaseSetUserProperty('email', this.appUser.email);

        return MyDb.userDbInfo();
      }
    }).catch(err => {
      this.appUser = { _id: MyUtil.DOC_ID.APP_USER };
    });
  }

  forgetAppUser(destroy: boolean = false): Promise<any> {
    if (!this.appUser) {
      return Promise.resolve('app user not found');
    }

    return MyDb.appRemove(this.appUser).then(() => {
      delete this.appUser;
      this.appUser = { _id: MyUtil.DOC_ID.APP_USER };

      return MyDb.forgetUserDb(destroy);
    });
  }

  isLoggedIn(): boolean {
    return (this.appUser && this.appUser.api_token);
  }

  isInitialized(): boolean {
    return (this.appUser);
  }

  loadAppHelpStatus(): Promise<any> {
    return MyDb.appLoad(MyUtil.DOC_ID.APP_HELP_STATUS).then(doc => {
      MyUtil.context.helpStatus = doc;
    }).catch(err => {
      MyUtil.context.helpStatus = { _id: MyUtil.DOC_ID.APP_HELP_STATUS };
    });
  }

  setAppHelpStatus(key: string, value: any): Promise<any> {
    MyUtil.context.helpStatus[key] = value;
    return MyDb.appSave(MyUtil.context.helpStatus).then(doc => {
      return doc;
    }).catch(err => {
      MyUtil.error(err);
    });;
  }

  openAppLawBrowser(uri: string): InAppBrowserObject {
    let url = MyUtil.context.API_SERVER_URL + '/law/' + uri;
    let target = '_blank';
    let options = 'location=no;hidden=no;clearcache=yes;clearsessioncache=yes;zoom=no;hardwareback=yes;closebuttoncaption=Close;toolbar=no';
    MyUtil.debug(url);
    return MyUtil.createInAppBrowser(url, target, options);
  }

  openAppHelpBrowser(uri: string): InAppBrowserObject {
    let url = MyUtil.context.API_SERVER_URL + '/app-help?uid=' + this.appUser.id + '&token=' + this.appUser.api_token + '&redirect=' + uri;
    let target = '_blank';
    let options = 'location=no;hidden=no;clearcache=yes;clearsessioncache=yes;zoom=no;hardwareback=yes;closebuttoncaption=Close;toolbar=no';
    MyUtil.debug(url);
    return MyUtil.createInAppBrowser(url, target, options);
  }

  getActivityExport(uri: string, data: any) {
    let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + '/activities-export?uid=' + this.appUser.id + '&token=' + this.appUser.api_token + '&date_from=' + data.date_from + '&date_to=' + data.date_to + '&redirect=' + uri;
    return url;
  }
  getActivityExportPDF(uri: string, data: any) { 
    let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + '/usertranscript/pdftranscript?uid=' + this.appUser.id + '&token=' + this.appUser.api_token + '&date_from=' + data.date_from + '&date_to=' + data.date_to + '&redirect=' + uri; 
    return url; 
  }

  openBrowser(uri: string): InAppBrowserObject {
    let url = uri;
    let target = '_blank';
    let options = 'location=no;hidden=no;clearcache=yes;clearsessioncache=yes;zoom=no;hardwareback=yes;closebuttoncaption=Close;toolbar=no';
    MyUtil.debug(url);
    return MyUtil.createInAppBrowser(url, target, options);
  }

  getEvidenceFileUrl(user_activity_id, file_id) {
    let url = MyUtil.context.API_SERVER_URL + '/' + this.getEvidenceFileUri(user_activity_id, file_id);
    url = url + '?uid=' + this.appUser.id + '&token=' + this.appUser.api_token;
    MyUtil.debug(url);
    return url;
  }

  getEvidenceFileUri(user_activity_id, file_id) {
    let uri = 'managedfile/show-evidence/' + user_activity_id + '/' + file_id;
    return uri;
  }

  get(uri: string, data: any, context?: any) {
    let options = {
      uri: uri,
      method: 'GET',
      data: data
    }
    return this.invokeApi(options, context);
  }

  post(uri: string, data: any, context?: any, appendServerURL?:boolean) {

    if (appendServerURL === undefined) {
      appendServerURL = true;
    }
    let options = {
      uri: uri,
      method: 'POST',
      data: data
    }
    return this.invokeApi(options, context, appendServerURL);
  }

  delete(uri: string, data: any, context?: any) {
    let options = {
      uri: uri,
      method: 'DELETE',
      data: data
    }
    return this.invokeApi(options, context);
  }

  blob(uri: string): Promise<any> {
    let options = {
      uri: uri,
      method: 'GET',
      more: {
        responseType: "blob"
      }
    };
    return this.invokeApi(options);
  }

  invokeApi(options: any, context?: any, appendServerURL?:boolean): Promise<any> {

    if (appendServerURL === undefined) {
      appendServerURL = true;
    }
    return new Promise((resolve, reject) => {
      MyUtil.debug(['invoke app api', options]);
      let url = (appendServerURL)? MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + options.uri : options.uri;
      //let url = MyUtil.context.API_SERVER_URL + MyUtil.context.API_PATH + options.uri;
      if (MyUtil.context.DEBUG) {
        url = url + '?XDEBUG_SESSION_START=dbgp';
      }

      //Make sure oid passed in
      if (!options.data.oid) {
        let localOid = MyUtil.retrieveFromLocalStorage('localOid');
        if (localOid) {
          options.data.oid = localOid;
        }
      }

      let headers = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest', // I am a ajax call
        'Accept': 'application/json', // I wants Json response
      };

      if (this.isLoggedIn()) {
        headers['Authorization'] = 'Bearer ' + this.appUser.api_token;
      }
      if (options.data.rsctoken) {
        headers['Authorization'] = 'Bearer ' + options.data.rsctoken;
      }

      let params = null;
      let observable: Observable<Object> = null;
      switch (options.method) {
        case 'GET':
          params = {
            headers: headers,
            params: options.data
          };

          if (options.more) {
            params = MyUtil.lodash.merge(params, options.more);
          }

          observable = this.httpClient.get(url, params);
          break;
        case 'DELETE':
          params = {
            headers: headers,
            params: options.data
          };

          if (options.more) {
            params = MyUtil.lodash.merge(params, options.more);
          }

          observable = this.httpClient.delete(url, params);
          break;
        case 'POST':
          if (options.data instanceof FormData) {
            delete headers['Content-Type'];
            options.data.append('oid', options.data.oid);
          }

          params = {
            headers: headers
          };

          if (options.more) {
            params = MyUtil.lodash.merge(params, options.more);
          }

          observable = this.httpClient.post(url, options.data, params);
          break;
      }

      observable.pipe(timeout(MyUtil.context.API_TIMEOUT))
        .subscribe(result => {
          MyUtil.debug(['invoke app api result', result]);
          
          //Server error handling
          if(result['#status'] == 'error') {
            //Something went wrong on the server - display generic message to user with Helpdesk reporting info
            let errorMessage = 'Unexpected error.';
            let logCode = '';
            if(result['#data'] && result['#data']['logcode']) {
              logCode = result['#data']['logcode'];
            }
            MyUtil.showErrorAlert(errorMessage, true, logCode);

            reject(result['#message']);
          } else {
            resolve(result);
          }        
        }, err => {
          MyUtil.error(['invoke app api err', err]);

          // handle error in general, e.g. 401 Unauthorized
          if (err && err.status === 401) {
            let args = {
              'handleUnauthorized': (context ? context.handleUnauthorized : null)
            };

            // If Unauthorised forward to login screen
            this.forgetAppUser(true).then(() => {
              this.router.navigate(['/reload-login']);
            });
            MyUtil.publishEvent(MyUtil.EVENT.APP_UNAUTHORIZED, args);

          /* } else if (err && err.status === 413) {
            //Content too large
            //MyUtil.publishEvent(this.const.EVENT.TOO_LARGE, {});
          } else {
            let args = {
              'handleServerUnavailable': (context ? context.handleServerUnavailable : null)
            };
            //MyUtil.publishEvent(this.const.EVENT.SERVER_UNAVAILABLE, args);
          } */
          } else {
            //Something else went wrong with the request - display generic message to user with Helpdesk reporting info
            let errorMessage = 'Unexpected error.';
            let logCode = '';
            if(err['error'] && err['error']['#data'] && err['error']['#data']['logcode']) {
              logCode = err['error']['#data']['logcode'];
            }
            MyUtil.showErrorAlert(errorMessage, true, logCode);
          }

          reject(err);
        });
    });
  }

  /* clear all timestamp to force re-sync, e.g. after profile changed */
  clearTimestampToForceFullSync(): Promise<any> {
    // force refresh by setting ts as null
    return Promise.all([
      MyDb.userMerge({ _id: MyUtil.DOC_ID.ACTIVITIES, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.ACTIVITY_TEMPLATES, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.GOALS, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.USER_FUNDING_SKILLS, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.USER_FUNDING_PROGRAMS, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.USER_FUNDING_PHASES, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.FUNDING_ACTIVITIES, ts: null }),
      MyDb.userMerge({ _id: MyUtil.DOC_ID.USER_FUNDING_GOALS, ts: null }),
    ]);
  }

  /* sync app all from server */
  syncAppAll(): Promise<any> {
    return Promise.all([
      this.syncAppComponent(MyUtil.DOC_ID.APP_SETTINGS, '/sync/app', {}, this.processMergeAndCache),
      this.syncAppComponent(MyUtil.DOC_ID.APP_META, '/sync/meta', {}, this.processRefreshAndCache),
      this.syncAppComponent(MyUtil.DOC_ID.APP_PAGES, '/sync/pages', {}, this.processMergeAndCache),
      this.syncAppComponent(MyUtil.DOC_ID.APP_UNIVERSITIES, '/sync/universities', {}, this.processMergeAndCache),
      this.loadAppHelpStatus(),
    ]).then(results => {
      // do something for all results
      return results;
    });
  }

  /* sync user all from server */
  syncUserAll(): Promise<any> {
    return this.syncUserProfile().then(() => { // initialize profile firstly
      return this.saveUserActivities().then(() => { // save user activities firstly to get activity id
        return Promise.all([ // save local unsaved user goals and user activities to server secondarly
          this.saveUserGoals(),
          this.saveActivityNotes(),
        ]).then(() => {
          return Promise.all([ // sync from server thirdly
            this.syncUserComponent(MyUtil.DOC_ID.USER_ORGANIZATIONS, '/sync/organizations', {}, this.prepareDeltaUpdate, this.processUserOrganizations),
            this.syncUserComponent(MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS, '/sync/funding-organizations', {}, this.prepareDeltaUpdate, this.processUserOrganizations),
            this.syncUserComponent(MyUtil.DOC_ID.USER_SKILLS, '/sync/skills', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
            this.syncUserComponent(MyUtil.DOC_ID.USER_PROGRAMS, '/sync/programs', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
            this.syncUserComponent(MyUtil.DOC_ID.USER_PHASES, '/sync/phases', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
            this.syncUserComponent(MyUtil.DOC_ID.USER_FUNDING_SKILLS, '/sync/funding-skills', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
            this.syncUserComponent(MyUtil.DOC_ID.USER_FUNDING_PROGRAMS, '/sync/funding-programs', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
            this.syncUserComponent(MyUtil.DOC_ID.USER_FUNDING_PHASES, '/sync/funding-phases', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
          ]);
        });
      });
    });
  }

  syncOrganization(): Promise<any> {
    return this.syncUserComponent(MyUtil.DOC_ID.USER_ORGANIZATIONS, '/sync/organizations', {}, this.prepareDeltaUpdate, this.processUserOrganizations);
  }


  syncAllActivities(): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.ACTIVITIES, '/sync/activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.ACTIVITY_TEMPLATES, '/sync/activity-templates', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.FUNDING_ACTIVITIES, '/sync/funding-activities', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserActivities(),
      this.syncActivityNotes(),
    ]);
  }

  syncActivityTemplates(): Promise<any> {
    return this.syncUserComponent(MyUtil.DOC_ID.ACTIVITY_TEMPLATES, '/sync/activity-templates', {}, this.prepareDeltaUpdate, this.processMergeAndCache);
  }

  syncAllGoals(): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.GOALS, '/sync/goals', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.USER_FUNDING_GOALS, '/sync/funding-goals', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserGoals(),
    ]);
  }

  syncAllFundings(): Promise<any> {
    return Promise.all([
      this.syncUserComponent(MyUtil.DOC_ID.ALL_FUNDING_ORGANIZATIONS, '/sync/all-funding-organizations', {}, this.prepareDeltaUpdate, this.processUserOrganizations),
      this.syncUserComponent(MyUtil.DOC_ID.ALL_FUNDING_PROGRAMS, '/sync/all-funding-programs', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
      this.syncUserComponent(MyUtil.DOC_ID.ALL_FUNDING_PHASES, '/sync/all-funding-phases', {}, this.prepareDeltaUpdate, this.processMergeAndCache),
    ]);
  }

  syncAppComponent(docId: string, uri: string, defaultData: any, processResult: any) {
    return this.syncComponent(docId, uri, defaultData, MyDb.appLoadWithDefault, null, processResult, MyDb.appSave, false);
  }

  syncUserComponent(docId: string, uri: string, defaultData: any, processUpdate: any, processResult: any) {
    return this.syncComponent(docId, uri, defaultData, MyDb.userLoadWithDefault, processUpdate, processResult, MyDb.userSave, true);
  }

  syncUserProfile() {
    return this.syncUserComponent(MyUtil.DOC_ID.USER_PROFILE, '/sync/profile', {}, (doc) => { return doc.data; }, this.processRefreshAndCache);
  }

  freshSyncUserProfile() {
    return this.syncUserComponent(MyUtil.DOC_ID.USER_PROFILE, '/sync/profile?refresh=true', {}, (doc) => { return doc.data; }, this.processRefreshAndCache);
  }

  syncUserGoals() {
    return this.queryUserGoals().then(queryResult => {
      let keys = (queryResult.length > 0 ? MyUtil.lodash.chain(queryResult).map('id').value() : []);
      let fields = (queryResult.length > 0 ? MyUtil.lodash.keys(queryResult[0]) : []);

      return this.syncUserComponent('user_personal_goals_no_cache', '/sync/user-goals', {}, () => {
        return {
          keys: keys,
          fields: fields
        };
      }, this.processUserGoals);
    });
  }

  syncUserActivities() {
    return this.queryUserActivity().then(queryResult => {
      let keys = (queryResult.length > 0 ? MyUtil.lodash.chain(queryResult).map('id').value() : []);
      let fields = (queryResult.length > 0 ? MyUtil.lodash.keys(queryResult[0]) : []);

      return this.syncUserComponent('user_personal_activities_no_cache', '/sync/user-activities', {}, () => {
        return {
          keys: keys,
          fields: fields
        };
      }, this.processUserActivities);
    });
  }

  syncActivityNotes() {
    let aaa = 1;
    aaa = aaa + 1;

    return this.queryActivityNotes().then(queryResult => {
      let ts = (queryResult.length > 0 ? MyUtil.lodash.chain(queryResult).maxBy((data) => {
        return parseInt(data.updated_at);
      }).value() : {});
      ts = ts.updated_at;
      let keys = (queryResult.length > 0 ? MyUtil.lodash.chain(queryResult).map('id').value() : []);
      let fields = (queryResult.length > 0 ? MyUtil.lodash.keys(queryResult[0]) : []);

      return this.syncUserComponent('user_activity_nots_no_cache', '/sync/activity-notes', {}, () => {
        return {
          ts: ts,
          keys: keys,
          fields: fields
        };
      }, this.processActivityNotes);
    });
  }

  // general sync function for app or user
  private syncComponent(docId: string, uri: string, defaultData: any, processLoad: any, processUpdate: any, processResult: any, processSave: any, needLoggedIn: boolean = true) {
    return this.allowSync(docId, needLoggedIn).then(() => {
      Appapi.syncFlags[docId] = true;

      // wrapper defaultData into default Doc
      let defaultDoc = {
        data: (defaultData ? defaultData : {})
      };
      return processLoad(docId, defaultDoc).then(doc => {
        // put to cache
        //@TODO determine those won't cached
        MyUtil.cache[doc._id] = doc.data;

        let data = {
          ts: doc.ts,
          data: null
        };

        // if need update server, prepare data to send here
        if (typeof (processUpdate) === 'function') {
          data.data = processUpdate(doc);
        }

        //@TODO check connection availability
        return this.post(uri, data).then(result => {
          //@TODO handle other status, e.g. updated, error, etc
          if (result['#status'] === 'success' && !MyUtil.lodash.isEmpty(result['#data'])) {
            // process doc and result['#data'], e.g. convert, update the cache
            processResult(doc, result['#data']);

            // write the update back
            return processSave(doc).then(() => {
              Appapi.syncFlags[docId] = false;
            }).catch(err => {
              // do something for save error
              Appapi.syncFlags[docId] = false;
            });
          } else { // updated, saved, etc.
            MyUtil.debug('no update for ' + docId);
            processResult(doc, null); // for cache purpose
            Appapi.syncFlags[docId] = false;
          }
        }).catch(err => {
          // do something for post error
          processResult(doc, null); // for cache purpose
          Appapi.syncFlags[docId] = false;
          throw err; // enable next level to process the error, e.g. unauthorize
        });
      }).catch(err => {
        // do something for load error
        Appapi.syncFlags[docId] = false;
        throw err; // enable next level to process the error, e.g. unauthorize
      });
    }).catch(err => {
      // prevent proceed for unauthorized but hide other errors
      if (err && err.status === 401) {
        throw err;
      }
    });
  }

  // check sync flag, login status, connection status, etc for sync
  // remember to set and remove flag in each sync
  private allowSync(docId: string, needLoggedIn: boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if (Appapi.syncFlags[docId]) {
        let message = 'ignore sync ' + docId;
        MyUtil.debug(message);
        reject(message);
      } else {
        if (needLoggedIn) {
          if (this.isLoggedIn()) {
            let message = 'allow user sync ' + docId;
            MyUtil.debug(message);
            resolve(message);
          } else {
            let message = 'ignore user sync ' + docId;
            MyUtil.debug(message);
            reject(message);
          }
        } else {
          let message = 'allow sync ' + docId;
          MyUtil.debug(message);
          resolve(message);
        }
      }
    });
  }

  processMergeAndCache(existing: any, updated: any): void {
    if (updated && updated.ts && updated.data) {
      existing.ts = updated.ts;
      if (!existing.data) {
        // init data
        existing.data = {};
      }

      // use assign to avoid recursive merge which keep the deleted sub items
      MyUtil.lodash.assign(existing.data, updated.data);
    }

    if (existing && existing.data && updated) {
      // clean data
      if (!MyUtil.lodash.isEmpty(updated.delete)) {
        MyUtil.lodash.forEach(updated.delete, (key) => {
          delete (existing.data[key]);
        });
      }
    }

    MyUtil.cache[existing._id] = existing.data;
  }

  processRefreshAndCache(existing: any, updated: any): void {
    if (updated && updated.ts && updated.data) {
      existing.ts = updated.ts;
      existing.data = updated.data;
    }

    MyUtil.cache[existing._id] = existing.data;
  }

  prepareDeltaUpdate(doc) {
    let data = { keys: [], fields: [] };

    // prepare keys to detect deleted
    data.keys = MyUtil.lodash.keys(doc.data);

    // prepare fields to detect fields add or remove
    if (!MyUtil.lodash.isEmpty(doc.data)) {
      data.fields = MyUtil.lodash.keys(MyUtil.lodash.values(doc.data)[0]);
    }
    return data;
  }

  processUserOrganizations(existing: any, updated: any): void {
    if (updated && updated.ts && updated.data) {
      existing.ts = updated.ts;
      if (!existing.data) {
        // init data
        existing.data = {};
      }

      // use assign to avoid recursive merge which keep the deleted sub items
      MyUtil.lodash.assign(existing.data, updated.data);
    }

    if (existing && existing.data && updated) {
      // clean data
      if (!MyUtil.lodash.isEmpty(updated.delete)) {
        MyUtil.lodash.forEach(updated.delete, (key) => {
          delete (existing.data[key]);
        });
      }
    }

    // cache
    MyUtil.cache[existing._id] = MyUtil.lodash.cloneDeep(existing.data);
    // build sub-tree
    MyUtil.lodash.forEach(MyUtil.cache[existing._id], (obj) => {
      if (obj.oid) {
        if (MyUtil.cache[existing._id][obj.oid]) {
          if (!MyUtil.cache[existing._id][obj.oid].children) {
            MyUtil.cache[existing._id][obj.oid].children = {};
          }
          MyUtil.cache[existing._id][obj.oid].children[obj.id] = obj;
        }
      }
    });

    if (!MyUtil.lodash.isEmpty(MyUtil.cache[existing._id])) {
      MyUtil.lodash.forEach(MyUtil.cache[existing._id], (org) => {
        let tempOrg = org;
        let level = 0;
        while (tempOrg.oid != null) {
          tempOrg = MyUtil.cache[existing._id][tempOrg.oid];
          level = level + 1;
        }
        org.level = level;
      });
    }
  }

  private processUserGoals(existing: any, updated: any): void {
    if (updated) {
      // save app missing user goals
      MyUtil.lodash.forEach(updated.data, (data, id) => {
        if (MyUtil.lodash.isObject(data) && data.id && data.ts) {
          // process data ids
          data.id = parseInt(data.id);
          data.goal_id = parseInt(data.goal_id);
          data.status = parseInt(data.status);
          data.started_at = (data.started_at ? parseInt(data.started_at) : null);
          data.completed_at = (data.completed_at ? parseInt(data.completed_at) : null);
          let ts = parseInt(data.ts);
          delete (data.ts);

          let doc = {
            _id: data.app_id,
            _rev: data.app_rev,
            ts: ts,
            type: MyUtil.DOC_TYPE.USER_GOAL,
            data: data
          };
          MyDb.userSave(doc);
        }
      });

      // clean server not existing user goals
      MyUtil.lodash.forEach(updated.delete, (id) => {
        let queryOptions: any = {
          key: [MyUtil.DOC_TYPE.USER_GOAL, id],
          include_docs: true
        };

        MyDb.userQuery('by_type_id', queryOptions).then(queryResult => {
          let docs = MyDb.flatQueryResult(queryResult);

          if (docs && docs.length > 0) {
            MyUtil.lodash.forEach(docs, (doc) => {
              if (doc._id) {
                MyDb.userRemove(doc);
              }
            });
          }
        });
      });
    }

    // remove memo cache if any
    delete (MyUtil.cache[existing._id]);
  }

  private processUserActivities(existing: any, updated: any): void {
    if (updated) {
      // save app missing user activities
      MyUtil.lodash.forEach(updated.data, (data, id) => {
        if (MyUtil.lodash.isObject(data) && data.id && data.ts) {
          // process data ids
          data.id = parseInt(data.id);
          data.activity_id = parseInt(data.activity_id);
          data.status = parseInt(data.status);
          data.start_at = (data.start_at ? parseInt(data.start_at) : null);
          data.end_at = (data.end_at ? parseInt(data.end_at) : null);
          data.time_logged = parseInt(data.time_logged);
          data.rating = (data.rating ? parseInt(data.rating) : null);
          data.completed_at = (data.completed_at ? parseInt(data.completed_at) : null);
          data.attend_status = (data.attend_status ? parseInt(data.attend_status) : null);
          let ts = parseInt(data.ts);
          delete (data.ts);

          let doc = {
            _id: data.app_id,
            _rev: data.app_rev,
            ts: ts,
            type: MyUtil.DOC_TYPE.USER_ACTIVITY,
            data: data
          };
          MyDb.userSave(doc);
        }
      });

      // clean server not existing user activities
      MyUtil.lodash.forEach(updated.delete, (id) => {
        let queryOptions: any = {
          key: [MyUtil.DOC_TYPE.USER_ACTIVITY, id],
          include_docs: true
        };

        MyDb.userQuery('by_type_id', queryOptions).then(queryResult => {
          let docs = MyDb.flatQueryResult(queryResult);

          if (docs && docs.length > 0) {
            MyUtil.lodash.forEach(docs, (doc) => {
              if (doc._id) {
                MyDb.userRemove(doc);
              }
            });
          }
        });
      });
    }

    // remove memo cache if any
    delete (MyUtil.cache[existing._id]);
  }

  private processActivityNotes(existing: any, updated: any): void {
    if (updated) {
      // save app missing user activities
      MyUtil.lodash.forEach(updated.data, (data, id) => {
        if (MyUtil.lodash.isObject(data) && data.activity_id && data.updated_at) {
          // process data ids
          data.activity_id = parseInt(data.activity_id);
          data.updated_at = parseInt(data.updated_at);

          let doc = {
            _id: data.app_id,
            _rev: data.app_rev,
            ts: data.updated_at,
            type: MyUtil.DOC_TYPE.ACTIVITY_NOTE,
            data: data
          };
          MyDb.userSave(doc);
        }
      });

      // clean server not existing activity note
      MyUtil.lodash.forEach(updated.delete, (activity_id) => {
        let queryOptions: any = {
          key: [MyUtil.DOC_TYPE.ACTIVITY_NOTE, activity_id],
          include_docs: true
        };

        MyDb.userQuery('by_type_activity_id', queryOptions).then(queryResult => {
          let docs = MyDb.flatQueryResult(queryResult);

          if (docs && docs.length > 0) {
            MyUtil.lodash.forEach(docs, (doc) => {
              if (doc._id) {
                MyDb.userRemove(doc);
              }
            });
          }
        });
      });
    }

    // remove memo cache if any
    delete (MyUtil.cache[existing._id]);
  }

  /**
   * query all user goals excluding deleted
   */
  queryUserGoals(): any {
    return this.queryUserDocByType(MyUtil.DOC_TYPE.USER_GOAL);
  }

  /**
   * query all user activities excluding deleted
   */
  queryUserActivity(): any {
    return this.queryUserDocByType(MyUtil.DOC_TYPE.USER_ACTIVITY);
  }
  /**
   * query all user activities excluding deleted
   */
  queryUserActivities(): any {
    return this.queryUserDocByType(MyUtil.DOC_TYPE.ACTIVITIES);
  }
  // MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES])
  /**
   * query all activity notes excluding deleted
   */
  queryActivityNotes(): any {
    return this.queryUserDocByType(MyUtil.DOC_TYPE.ACTIVITY_NOTE);
  }

  /**
   * query all user doc by type excluding deleted
   */
  private queryUserDocByType(type: string): any {
    let queryOptions: any = {
      key: type,
      include_docs: true
    };

    return MyDb.userQuery('by_type', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);
      let result = [];

      if (docs && docs.length > 0) {
        result = MyUtil.lodash.chain(docs).filter((item: any) => {
          return item && (!item.delete);
        }).map('data').value();
      }

      return result;
    });
  }


  ignoreLoggedHours(id): Promise<any> {
    return this.get('/organization/ignore-hours-logging/' + id, {}).then(res => {
      return new Promise((resolve, reject) => {

        if (res['#status'] === 'success') {

          Promise.all(res['#data']).then(() => {
            resolve(res['#data']);
          }).catch(err => {
            MyUtil.error(err);
            reject(err);
          });

        }

      });
    })

  }



  /**
   * save all user activities to server and
   * update the user db for deleted or ts, id
   */
  saveUserActivities(): Promise<any> {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.USER_ACTIVITY, null],
      include_docs: true
    };

    // get all not synchronized user activities, ts is null or undefined
    return MyDb.userQuery('by_type_ts', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);

      if (docs.length == 0) {
        //If no data then nothing to do
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      } else {
        return this.post('/save/user-activities', { data: docs }).then((result) => {
          return new Promise((resolve, reject) => {

            if (result['#status'] === 'success') {

              let allTasks: Array<any> = [];

              // updated user generated activities
              if (!MyUtil.lodash.isEmpty(result['#activities'])) {
                allTasks.push(this.updateUserGeneratedActivities(result['#activities']));
              }

              // update saved ts and data.id
              MyUtil.lodash.forEach(result['#data'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data.id && data.ts) {
                  allTasks.push(this.updateUserDocTsAndMore(data, docId));
                }
              });

              // clean deleted
              MyUtil.lodash.forEach(result['#delete'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data._rev) {
                  allTasks.push(this.removeUserDoc(data, docId));
                }
              });

              // make sure all tasks finished
              Promise.all(allTasks).then(() => {
                resolve(allTasks);
              }).catch(err => {
                MyUtil.error(err);
                reject(err);
              });
            } else {
              MyUtil.error(result['#message']);
              reject(result['#message']);
            }
          });
        }).catch(err => {
          console.log('err', err)
          MyUtil.error(err);
        });
      }
    });
  }

  /**
   * make usre user activities saved firstly !!!
   * save all user goals to server and
   * update the user db for deleted or ts, id
   */
  saveUserGoals() {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.USER_GOAL, null],
      include_docs: true
    };

    // get all not synchronized user goals, ts is null or undefined
    return MyDb.userQuery('by_type_ts', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);

      if (docs.length == 0) {
        //If no data then nothing to do
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      } else {
        return this.post('/save/user-goals', { data: docs }).then((result) => {

          return new Promise((resolve, reject) => {

            if (result['#status'] === 'success') {

              let allTasks: Array<any> = [];

              // updated user generated goals
              if (!MyUtil.lodash.isEmpty(result['#goals'])) {
                allTasks.push(this.updateUserGeneratedGoals(result['#goals']));
              }

              // update saved ts and data.id
              MyUtil.lodash.forEach(result['#data'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data.id && data.ts) {
                  allTasks.push(this.updateUserDocTsAndMore(data, docId));
                }
              });

              // clean deleted
              MyUtil.lodash.forEach(result['#delete'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data._rev) {
                  allTasks.push(this.removeUserDoc(data, docId));
                }
              });

              // make sure all tasks finished
              Promise.all(allTasks).then(() => {
                resolve(allTasks);
              }).catch(err => {
                MyUtil.error(err);
                reject(err);
              });
            } else {
              MyUtil.error(result['#message']);
              reject(result['#message']);
            }
          });
        }).catch(err => {
          MyUtil.error(err);
        });
      }
    });
  }

  /**
   * make usre user activities saved firstly !!!
   * save all activity notes to server and
   * update the user db for deleted or ts, id
   */
  saveActivityNotes() {
    let queryOptions: any = {
      key: [MyUtil.DOC_TYPE.ACTIVITY_NOTE, null],
      include_docs: true
    };

    // get all not synchronized activity note, ts is null or undefined
    return MyDb.userQuery('by_type_ts', queryOptions).then(queryResult => {
      let docs = MyDb.flatQueryResult(queryResult);

      if (docs.length == 0) {
        //If no data then nothing to do
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      } else {
        return this.post('/save/activity-notes', { data: docs }).then((result) => {
          return new Promise((resolve, reject) => {

            if (result['#status'] === 'success') {

              let allTasks: Array<any> = [];

              // update saved updated_at
              MyUtil.lodash.forEach(result['#data'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data.updated_at) {
                  allTasks.push(this.updateUserDocTsAndMore(data, docId));
                }
              });

              // clean deleted
              MyUtil.lodash.forEach(result['#delete'], (data, docId) => {
                if (MyUtil.lodash.isObject(data) && data._rev) {
                  allTasks.push(this.removeUserDoc(data, docId));
                }
              });

              // make sure all tasks finished
              Promise.all(allTasks).then(() => {
                resolve(allTasks);
              }).catch(err => {
                MyUtil.error(err);
                reject(err);
              });
            } else {
              MyUtil.error(result['#message']);
              reject(result['#message']);
            }
          });
        }).catch(err => {
          MyUtil.error(err);
        });
      }
    });
  }

  private updateUserGeneratedGoals(goalIdMappings: any): any {
    return MyDb.userLoad(MyUtil.DOC_ID.GOALS).then((goalsDoc: any) => {
      MyUtil.lodash.forEach(goalIdMappings, (goalIdMapping, uuid) => {
        // replace uuid with server side id
        if (goalsDoc.data[uuid]) {
          let goal = goalsDoc.data[uuid];
          goal.id = goalIdMapping.id;
          delete goalsDoc.data[uuid];
          goalsDoc.data[goal.id] = goal;
        }
      });

      return MyDb.userSave(goalsDoc).then((goalsDoc: any) => {
        // update cache
        MyUtil.cache[goalsDoc._id] = goalsDoc.data;
      });
    });
  }

  private updateUserGeneratedActivities(activityIdMappings: any): any {
    return MyDb.userLoad(MyUtil.DOC_ID.ACTIVITIES).then((activitiesDoc: any) => {
      return new Promise((resolve, reject) => {
        let allTasks: Array<any> = [];

        // process activities
        MyUtil.lodash.forEach(activityIdMappings, (activityIdMapping, uuid) => {
          // replace uuid with server side id
          if (activitiesDoc.data[uuid]) {
            let activity = activitiesDoc.data[uuid];
            activity.id = parseInt(activityIdMapping.id);
            delete activitiesDoc.data[uuid];
            activitiesDoc.data[activity.id] = activity;
          }
        });

        allTasks.push(MyDb.userSave(activitiesDoc).then((activitiesDoc: any) => {
          // update cache
          MyUtil.cache[activitiesDoc._id] = activitiesDoc.data;
        }));

        // mapping for user generated goals and activity notes
        allTasks.push(this.updateIdReferenceOfUserGeneratedActivity(activityIdMappings));

        // make sure all tasks finished
        Promise.all(allTasks).then(() => {
          resolve(allTasks);
        }).catch(err => {
          MyUtil.error(err);
          reject(err);
        });
      });
    });
  }

  private updateIdReferenceOfUserGeneratedActivity(activityIdMappings: any): any {
    return Promise.all([
      this.updateIdReferenceOfUserGeneratedActivityInActivityNotes(activityIdMappings),
      this.updateIdReferenceOfUserGeneratedActivityInUserGeneratedGoals(activityIdMappings),
    ]);
  }

  private updateIdReferenceOfUserGeneratedActivityInActivityNotes(activityIdMappings: any): any {
    return new Promise((resolve, reject) => {
      let allTasks: Array<any> = [];

      MyUtil.lodash.forEach(activityIdMappings, (activityIdMapping, uuid) => {
        // process activity note
        let queryOptions: any = {
          key: [MyUtil.DOC_TYPE.ACTIVITY_NOTE, uuid],
          include_docs: true
        };

        allTasks.push(MyDb.userQuery('by_type_activity_id', queryOptions).then(queryResult => {
          let docs = MyDb.flatQueryResult(queryResult);
          if (docs && docs.length > 0) {
            // assume only one to one mapping
            let doc = docs[0];
            doc.data.activity_id = parseInt(activityIdMapping.id);
            return MyDb.userSave(doc);
          } else {
            return;
          }
        }));
      });

      // make sure all tasks finished
      Promise.all(allTasks).then(() => {
        resolve(allTasks);
      }).catch(err => {
        MyUtil.error(err);
        reject(err);
      });
    });
  }

  private updateIdReferenceOfUserGeneratedActivityInUserGeneratedGoals(activityIdMappings: any): any {
    let uuids = MyUtil.lodash.keys(activityIdMappings);
    return MyDb.userLoad(MyUtil.DOC_ID.GOALS).then((goalsDoc: any) => {
      return new Promise((resolve, reject) => {
        let allTasks: Array<any> = [];

        let changedGoalIds = {};
        MyUtil.lodash.forEach(goalsDoc.data, (goal, id) => {
          // find all all goal with uuids
          if (goal.activities && goal.activities.length > 0) {
            let realIds = MyUtil.lodash.difference(goal.activities, uuids);
            let myUuids = MyUtil.lodash.difference(goal.activities, realIds);
            if (myUuids && myUuids.length > 0) {
              // remeber goal ids and related uuids to process user goals
              changedGoalIds[id] = myUuids;

              // process each uuid
              MyUtil.lodash.forEach(myUuids, (uuid) => {
                // replace uuid with server side id
                let activities = goalsDoc.data[id].activities;

                // redudant check
                if (activities && activities.length > 0) {
                  let idx = activities.indexOf(uuid);

                  // redudant check
                  if (idx !== -1) {
                    goalsDoc.data[id].activities[idx] = parseInt(activityIdMappings[uuid].id);
                  }
                }
              });
            }
          }
        });

        if (!MyUtil.lodash.isEmpty(changedGoalIds)) {
          // save update goalsDoc
          allTasks.push(MyDb.userSave(goalsDoc).then((goalsDoc: any) => {
            // update cache
            MyUtil.cache[goalsDoc._id] = goalsDoc.data;
          }));

          // process related user goals if any
          MyUtil.lodash.forEach(changedGoalIds, (myUuids, goal_id) => {
            // process user goal
            let queryOptions: any = {
              key: [MyUtil.DOC_TYPE.USER_GOAL, goal_id],
              include_docs: true
            };

            allTasks.push(MyDb.userQuery('by_type_goal_id', queryOptions).then(queryResult => {
              let docs = MyDb.flatQueryResult(queryResult);
              if (docs && docs.length > 0) {
                // assume only one to one mapping
                let doc = docs[0];

                // process each uuid
                MyUtil.lodash.forEach(myUuids, (uuid) => {
                  // replace uuid with server side id
                  let activities = (doc.data && doc.data.updated_goal && doc.data.updated_goal.activities ? doc.data.updated_goal.activities : null);

                  // redudant check
                  if (activities && activities.length > 0) {
                    let idx = activities.indexOf(uuid);

                    // redudant check
                    if (idx !== -1) {
                      doc.data.updated_goal.activities[idx] = parseInt(activityIdMappings[uuid].id);
                    }
                  }
                });

                return MyDb.userSave(doc);
              } else {
                return;
              }
            }));
          });
        }

        // make sure all tasks finished
        Promise.all(allTasks).then(() => {
          resolve(allTasks);
        }).catch(err => {
          MyUtil.error(err);
          reject(err);
        });
      });
    });
  }

  /**
   * Update user doc according to server return
   * UserActivity, UserGoal, ActivityNote
   */
  private updateUserDocTsAndMore(data: any, docId: any): any {
    let doc: any = {
      _id: docId,
      data: {}
    };

    // set ts from server
    if (data.ts) {
      doc.ts = parseInt(data.ts);
    }

    // set id from server
    if (data.id) {
      doc.data.id = parseInt(data.id);
    }

    // set updated_at from server
    if (data.updated_at) {
      doc.data.updated_at = parseInt(data.updated_at);
      doc.ts = data.updated_at;
    }

    // replace uuid with server side activity id
    if (data.activity_id) {
      doc.data.activity_id = parseInt(data.activity_id);
      if (doc.data.updated_activity) {
        doc.data.updated_activity = null;
      }
    }

    // replace uuid with server side goal id
    if (data.goal_id) {
      doc.data.goal_id = parseInt(data.goal_id);
      if (doc.data.updated_goal) {
        doc.data.updated_goal = null;
      }
    }

    // set attend status if any
    if (data.attend_status) {
      doc.data.attend_status = parseInt(data.attend_status);
    }

    // merge ts and data.id
    return MyDb.userMerge(doc);
  }

  /**
   * Remove user doc according to server return
   * UserActivity, UserGoal, ActivityNote
   */
  private removeUserDoc(data: any, docId: any): any {
    let doc = {
      _id: docId,
      _rev: data._rev
    };

    // remove rev
    return MyDb.userRemove(doc);
  }

  getUserBadges() {
    return this.get('/sync-badges', {});
  }

  getQuestionnaire() {
    return this.get('/questionnaire', {});
  }

  restartQuestionnaire(oid: number) {
    return this.post('/questionnaire/restart', { oid: oid });
  }

  saveQuestion(question) {
    return this.post('/question', {
      id: question.id,
      skill_level: question.skill_level,
      interest: question.interest,
      comments: question.comments,
    });
  }

  getMostRecentQuestionnaireResults() {
    return this.get('/questionnaire-results', {});
  }

  requestQuestionnaireResultsEmail() {
    return this.get('/questionnaire-results/request-email', {});
  }

  getCareerPathsByType() {
    return this.get('/careers/by-type', {});
  }

  getCareerPathsMatchingRequirements() {
    return this.get('/careers/matching-requirements', {});
  }

  getSavedCareerPaths() {
    return this.get('/careers/pdp/list', {});
  }

  saveCareerToPDP(careerPathId) {
    return this.post('/careers/pdp/add/' + careerPathId, {});
  }

  removeCareerFromPDP(careerPathId) {
    return this.post('/careers/pdp/remove/' + careerPathId, {});
  }

  setActiveCareer(careerPathId) {
    return this.post('/careers/pdp/set-active/' + careerPathId, {});
  }

  setQuestionnaireSkippedFlag() {
    return this.post('/questionnaire-set-skipped', {});
  }

  deactivateCareer(careerPathId) {
    return this.post('/careers/pdp/deactivate/' + careerPathId, {});
  }

  getBrandedSlides(pattern) {

    return this.get('/branding/slides?pattern=' + pattern, {});

  }

  getBrandedFavicon(pattern) {

    return this.get('/branding/favicon?pattern=' + pattern, {});

  }

  getRecommendedActivities() {
    return this.get('/find/recommended-activities', {});
  }

  getRecommendedGoals() {
    return this.get('/sync/recommended-goals', {});
  }

  getAlternativeProfilesData(branding: string): Promise<any> {
    
    let profile = MyUtil.getProfile();
    if(!branding) {
      branding = 'inkpath';
    }

    return MyDb.userLoad(MyUtil.DOC_ID.USER_PROFILE).then((doc: any) => {
      return this.get('/profile/' + profile.id + '/alt-list/' + branding, {}).then((result) => {
        return result["#data"]['alternativeProfilesData'];
      })
        .catch((err) => {
          MyUtil.error(err);
          return [];
        });
    }).catch((err) => {
      MyUtil.error(err);
      return [];
    });
  }

  /**
  * Set the reflection reminder sent flag
  */
  processReflectionReminderSeenFlag() {
    return this.post('/onboarding/reflection/reminder/seen', {});
  }

  //Refresh profile data if profile changed since the last profile sync (ie. editied by the admin)
  checkAndRefreshProfile(): Promise<any> {
    return this.hasProfileChanged().then(changed => {
      if (changed) {
        return this.clearTimestampToForceFullSync().then(() => {
          return this.syncUserAll();
        });
      }
    });
  }

  //Check if the profile has changed 
  hasProfileChanged(): Promise<any> {
    let profile = MyUtil.getProfile();
    return MyDb.userLoad(MyUtil.DOC_ID.USER_PROFILE).then((doc: any) => {
      let ts = doc.ts;
      let url = '/profile/' + profile.id + '/changed';
      return this.post(url, { ts: ts }).then((result) => {
        return result["#data"]['changed'] == '1';
      }).catch((err) => {
          MyUtil.error(err);
          return false;
      });
    }).catch((err) => {
      MyUtil.error(err);
      return false;
    });
  }


  deleteUserRequest(): Promise<any> {
    let profile = MyUtil.getProfile();
    let url = '/profile/' + profile.id + '/delete-request';

    return this.post(url, { }).then((result) => {
      return result["#message"];
    });
  }

  getRecurringGroup(groupId: number, eventId: number): Promise<any> {
    return this.get('/recurring-group/' + groupId + '/' + eventId, {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });    
  }

  getUnreadAnnouncementCount() : Promise<any> {
    return this.get('/announcement/get-unread-count', {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  getAnnouncementList() : Promise<any> {
    return this.get('/announcement/get-list', {}).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

  markAnnouncementAsRead(announcementId : number) : Promise<any> {
    return this.post('/announcement/read', { announcementId: announcementId }).then(result => {
      return result["#data"];
    }).catch((err) => {
      MyUtil.error(err);
      return null;
    });
  }

}
