import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ActionSheetController, AlertController, LoadingController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { State, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as appStore from '../app/store';
import { MyDb } from '../libs/MyDb';
import { MyUtil } from '../libs/MyUtil';
import { AccessibilityModalComponent } from './components/accessibility-modal/accessibility-modal.component';
import { Appapi } from './providers/appapi';
import { EventsService } from './providers/events-service';
import { getBrandName, getDisplayMode, getDisplayTheme, getFontSize, getMenuState, getSelectedActivityId } from './store/selectors/view.selector';
import { Capacitor } from '@capacitor/core';
//import { Socket } from 'ngx-socket-io';


declare var cordova: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  styleUrl: SafeResourceUrl;
  profileDisabled: boolean;
  profileOrg: any;
  displayMode: string;
  loading = true;
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);
  activities$: Observable<number> = this.store.select(getSelectedActivityId);
  displayMode$: Observable<string> = this.store.select(getDisplayMode);
  displayTheme$: Observable<string> = this.store.select(getDisplayTheme);
  fontSize$: Observable<string> = this.store.select(getFontSize);
  //@ViewChild('websocketLog', { static: true }) websocketLog:any;
  menuState$: Observable<string>         = this.store.select(getMenuState);
  menuState = '';

  // Set body classes.
  setBodyClasses$ = combineLatest([
    this.displayMode$/* ,
    this.displayTheme$,
    this.fontSize$ */
  ]).pipe(map(([
    displayMode/* ,
    displayTheme,
    fontSize */
  ]) => {
    this.setBodyClass(displayMode, ['dark-mode', 'light-mode', 'high-contrast-mode']);
    this.setBodyClass('static-theme', ['simple-theme', 'static-theme', 'animated-theme']);
    this.setBodyClass('small-font', ['small-font', 'medium-font', 'large-font']);

    if(Capacitor.getPlatform() === 'ios' && Capacitor.isNativePlatform() && this.platform.width() <= 820) {
      this.setBodyClass('dynamic-island', ['dynamic-island']);
    }
  }));

  constructor(
    // public sanitizer: DomSanitizer,
    private store: Store<appStore.AppViewState>, private state: State<any>,
    private router: Router,
    public platform: Platform,
    public events: EventsService,
    public actionSheetCtrl: ActionSheetController,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public popoverCtrl: PopoverController,
    public toastCtrl: ToastController,
    public device: Device,
    public statusBar: StatusBar,
    public splashScreen: SplashScreen,
    public network: Network,
    public inAppBrowser: InAppBrowser,
    public calendar: Calendar,
    public file: File,
    public appapi: Appapi,
    //private socket: Socket,
  ) { }

  ngOnInit() {
    // Watch for backend changes.
    //this.watchWebsockets();
  
    // Get branding from store
    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => { 
      this.branding = brandName;
      this.setCurrentAccessibilityState(brandName);
      if (this.branding !== '') {
        this.loading = false;
      } else {
        console.log('No branding set.')
      }
    })

    // Set display mode.
    this.displayMode$.pipe(takeUntil(this.destroy$)).subscribe(displayMode => {
      this.displayMode = displayMode;
    })

    // Set menu state from store.
    this.menuState$.pipe(takeUntil(this.destroy$)).subscribe((menuState)=>{
      this.menuState = menuState;
    })

    // Set body classes.
    this.setBodyClasses$.pipe(takeUntil(this.destroy$)).subscribe();

    // check for tracking permissions (iOS 14+ requirement)
    //if (this.platform.is('cordova') && (this.platform.is('ios') && this.device.version >= '14.5')) {
    this.showAppTrackingTransparency();
    //  }

    // initialize app, then db
    MyUtil.initializeApp({
      platform: this.platform,
      events: this.events,
      actionSheetCtrl: this.actionSheetCtrl,
      alertCtrl: this.alertCtrl,
      loadingCtrl: this.loadingCtrl,
      modalCtrl: this.modalCtrl,
      popoverCtrl: this.popoverCtrl,
      toastCtrl: this.toastCtrl,
      device: this.device,
      statusBar: this.statusBar,
      splashScreen: this.splashScreen,
      network: this.network,
      inAppBrowser: this.inAppBrowser,
      calendar: this.calendar,
      file: this.file
    }).then(() => {

      let hostname = window.location.hostname;
      if (hostname === MyUtil.context.RSC_URL) {
        MyUtil.addOneTrustTags().then(d => {
          MyUtil.addRSCTags();
        });
      }

      let loading = MyUtil.presentLoading();

      // initialize db
      MyDb.appDbInfo().then(() => {
        // load app cache and try sync from server
        this.appapi.syncAppAll().then(async () => {
          console.log("APP SYNC ALL");
          // check if app must be updated
          let upgradeRequired = MyUtil.appRequiresUpgrade();
          if (upgradeRequired !== null) {
            console.log("APP REQUIRES UPGRADE");
            // log the user out
            if (this.appapi.isLoggedIn()) {
              this.appapi.forgetAppUser(true);
            }
            // show alert
            MyUtil.presentAlert({
              title: 'App Requires Update',
              message: upgradeRequired,
              buttons: [
                {
                  text: 'OK',
                }
              ]
            });

            this.router.navigate(['/reload-login']);

          } else {

            // Set branding in store
            let pattern = encodeURI(window.location.href);
            let brandingURL = '/branding/org?pattern=' + pattern;
            await this.appapi.get(brandingURL, {}).then(async (test) => {
              if(test && test['#data']) {
                //Store the branding details
                this.store.dispatch(new appStore.BrandingSet(test['#data'].name));
                if(test['#data']['name'] && test['#data']['oid']) {
                  if(test['#data']['name'] != 'inkpath') {
                    let localOid = MyUtil.retrieveFromLocalStorage('localOid');
                    if(localOid) {
                      //If localOid already stored, check that it's valid for this url
                      let validOids = [];
                      if(test['#data']['valid_oids']) {
                        validOids = test['#data']['valid_oids'];
                      }

                      if(!validOids.includes(Number(localOid))) {
                        //Not valid - change to the valid oid returned by the api
                        MyUtil.saveToLocalStorage('localOid', test['#data']['oid']);

                        //Also need to log the user out, if they were logged in
                        await this.appapi.initializeAppUser();
                        await this.appapi.forgetAppUser(true);
                      }
                    } else {
                      MyUtil.saveToLocalStorage('localOid', test['#data']['oid']);
                    }
                  }
                }
              }
            
              // preapre the cached app user
              this.appapi.initializeAppUser().then(async () => {
                if (this.appapi.isLoggedIn()) {
                  // load user cache and try sync from server
                  this.appapi.syncUserAll().then(async (user) => {
                    // Check if user has completed onboarding questionnaire
                    this.appapi.getMostRecentQuestionnaireResults().then(async res => {
                        (await loading).dismiss();
                        if (res['#status'] === 'success') {
                          this.router.navigate(['/home']);
                        } else {
                          if (!res['#skipQuestionnaire']) {
                            this.router.navigate(['/SkipQuestionnaire']);
                          } else {
                            this.router.navigate(['/home']);
                          }
                        }
                      })
                      .catch(async err => {
                          (await loading).dismiss();
                          this.router.navigate(['/home']);
                      });
                  }).catch(async (err) => {
                    (await loading).dismiss();
                    MyUtil.error('error 2 = '+ err);
                    this.appapi.forgetAppUser(true);
                  });
                  
                } else {
                  (await loading).dismiss();

                  // check if we need to show a new login page (RSC specific currently)
                  let hostname = window.location.hostname;
                  
                  if(hostname === MyUtil.context.RSC_URL) {
                    this.router.navigate(['/rsc/reload-auth']);
                  } else {
                  
                    this.router.navigate(['/reload-login']);

                    /* // decide to go to welcome page, login page or home page
                    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.WELCOME]) {
                      this.router.navigate(['/reload-login']);
                    } else {
                      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.WELCOME, true).then(() => {
                        this.router.navigate(['/home']);
                      });
                    } */
                  }
                }

                // prepare to handle Unauthorized
                MyUtil.subscribeEvent(MyUtil.EVENT.APP_UNAUTHORIZED, (data) => {
                  this.router.navigate(['/reload-login']);
                });
              });
            });
          }
        })
      })
    });
  }


  /** 
  * Websockets
  */
  /* socket = io("http://localhost:3000/", {"transports": ["websocket"]});

  watchWebsockets(){
    // Start connection.
    this.socket.on('connection',(connection)=>{
      console.log('Frontend Connected', connection)
    });
    // Gets the message from the websockets and applies required action.
    this.socket.on('sendMessageToFrontend', (message)=>{
      switch (message) {
        case 'sync-all-activities':
          console.log('websocket work! activities synced!')
          //this.snotifyService.success('Syncing Activities');
          //this.appapi.syncAllActivities();
          break;
        default:
          console.log('No websocket action defined.')
          break;
      }
    })
  } */

  /** 
  * Set walkthrough state. 
  * @param branding   Brand name.
  */
  setCurrentAccessibilityState(branding) {
    let displayMode = MyUtil.retrieveFromLocalStorage('displayMode'),
      displayTheme = MyUtil.retrieveFromLocalStorage('displayTheme'),
      fontSize = MyUtil.retrieveFromLocalStorage('fontSize');

    // Set display mode.
    if (displayMode) {
      if(displayMode == 'dark-mode' && (branding === 'rsc' || branding === 'harvardmedical' || branding === 'bpsn' || branding === 'lancaster')) {
        //Force use of light mode
        this.store.dispatch(new appStore.DisplayModeSet('light-mode'));
      } else {
        this.store.dispatch(new appStore.DisplayModeSet(displayMode));
      }
    } else {
      //Default initial state to light mode
      if (branding === 'imperial'
        || branding === 'glasgow'
        || branding === 'harvardmedical'
        || branding === 'enspire'
        || branding === 'rmit'
        || branding === 'bpsn'
        || branding === 'lancaster'
        || branding === 'rsc') {
        this.store.dispatch(new appStore.DisplayModeSet('light-mode'));
      }
    }    

    // Set display theme.
    if (displayTheme) {
      this.store.dispatch(new appStore.DisplayThemeSet(displayTheme));
    }
    // Set font size.
    if (fontSize) {
      this.store.dispatch(new appStore.FontSizeSet(fontSize));
    }
  }

  /** 
  * Set walkthrough state. 
  * @param newClass   Class to add to body.
  * @param classRemovalArray  Classes to remove from body.
  */
  setBodyClass(newClass, classRemovalArray) {
    let bodyClassList = document.body.classList;
    classRemovalArray.forEach(className => {
      bodyClassList.remove(className)
    });
    bodyClassList.add(newClass)
  }

  /** 
  * Show App Tracking Transparency.
  */
  showAppTrackingTransparency() {
    return this.platform
      .ready()
      .then(() => {
        if (this.platform.is('cordova') && (this.platform.is('ios') && this.device.version >= '14.5')) {
          const idfaPlugin = cordova.plugins.idfa;
          idfaPlugin.getInfo().then((info) => {
            if (!info.trackingLimited) {
              return info.idfa || info.aaid;
            } else if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
              return idfaPlugin.requestPermission().then((result) => {
                if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                  return idfaPlugin.getInfo().then((info) => {
                    return info.idfa || info.aaid;
                  });
                }
              });
            }
          });
        }
      })
  }

  /** 
  * Accessibility modal handler.
  */
  public async accessibilityHandler() {
    let accessibilityModal = MyUtil.createModal(AccessibilityModalComponent, {});
    (await accessibilityModal).onDidDismiss().then((data: any) => { });
    (await accessibilityModal).present();
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(actionItem) {
    if (MyUtil.isNetworkConnected() || actionItem == 'about-app') {
      switch (actionItem) {
        case 'open-accessibility':
          this.accessibilityHandler();
          break;
        case 'show-website':
          this.appapi.openBrowser('https://www.inkpath.co.uk/');
          break;
        case 'terms-of-use':
          this.appapi.openAppLawBrowser('terms_and_conditions');
          break;
        case 'privacy-policy':
          this.appapi.openAppLawBrowser('privacy_policy');
          break;
      }
    } else {
      MyUtil.presentToast('Please check the network connection and try again later.', { cssClass: 'inkpath-toast' });
    }
  }

  mobileNavToggle(){
    if(this.menuState === 'inactive-mobile-menu'){
      this.store.dispatch(new appStore.MenuStateSet('active-mobile-menu'));
    }else{
      this.store.dispatch(new appStore.MenuStateSet('inactive-mobile-menu'));
    }
  }  

  // Back button handler.
  backClicked(event: any) {
    event.preventDefault();
    this.store.dispatch(new appStore.MenuStateSet('inactive-mobile-menu'));
    //this._location.back();
    window.history.back();
  }

  // Check profile complete.
  checkProfileComplete(){
    // check if profile has been completed
    return (MyUtil.validateProfile()) ? true: false;
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
